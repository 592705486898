@keyframes movedown {
    0% {
        opacity: 0;
        transform: translateY(-50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes moveright {
    0% {
        opacity: 0;
        transform: translateX(-50px);
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

.cardInfo_card {
    opacity: 0;
    animation: movedown 1s linear forwards;
}


.cardInfo_card:nth-child(1) {
    animation-delay: 0s;
}

.cardInfo_card:nth-child(2) {
    animation-delay: .5s;
}

.cardInfo_card:nth-child(3) {
    animation-delay: 1s;
}

@media screen and (max-width: 600px) {
    .cardInfo_card {
        opacity: 0;
        animation: moveright 1s linear forwards;
    }
}