* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

html,


body {
  height: 100%;
  margin: 0;
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
  background-color: #F9F9F9;
  font-family: 'Satoshi', sans-serif;
}


.spaceicon {


  padding: 10px;
  border-radius: 10px;
  height: 50px;
  /* Assuming the icon's height */
  width: 50px;
  /* Assuming the icon's width */
  margin-right: 10px;

  border: 2px solid #52525B;
}


fieldset {
  border: none !important;
  outline: none !important;
}

#woot-widget-bubble-icon {
  display: none !important;
  /* Oculta o ícone SVG original */
}


.woot-widget-bubble.woot--close::after {
  background-color: var(--vibrant-purple) !important;
  content: '';
  position: absolute;
  /* Posição absoluta para o pseudo-elemento */
  top: 50%;
  /* Centraliza verticalmente */
  left: 50%;
  /* Centraliza horizontalmente */
  transform: translate(-50%, -50%);
  /* Ajusta a posição precisa para o centro */
  width: 30px;
  /* Largura do seu ícone */
  height: 30px;
  /* Altura do seu ícone */
  background-image: url('./assets/Icons/svg/Ativo 1.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}



.px-0.py-3.flex.justify-center {
  display: none !important;
}

/* Estiliza o ícone de balão de chat */
.woot-widget-bubble {
  background-color: #7B25FF !important;
  border: 'none' !important;
  border-radius: 50% !important;
  color: #fff !important;
  font-size: 1.5rem !important;
  height: 60px !important;
  width: 60px !important;
  position: fixed !important;
  bottom: 20px !important;
  right: 20px !important;
  z-index: 9999 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;
  box-shadow: none !important;

}

.show-tooltip {
  pointer-events: none;
}

.woot-widget-bubble::after {
  border: 'none' !important;
  content: '';
  position: absolute;
  /* Posição absoluta para o pseudo-elemento */
  top: 50%;
  /* Centraliza verticalmente */
  left: 50%;
  /* Centraliza horizontalmente */
  transform: translate(-50%, -50%);
  /* Ajusta a posição precisa para o centro */
  width: 50px;
  /* Largura do seu ícone */
  height: 50px;
  /* Altura do seu ícone */
  background-image: var(--background-image-url, url('./assets/Icons/svg/Ativo 1.svg'));
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}



.woot-widget-bubble {
  border-radius: 5px !important;
}

:root {

  /* PURPLE COLORS */
  --purple-50: #F5F3FF;
  --purple-100: #EDE8FF;
  --purple-200: #DDD4FF;
  --purple-300: #C5B2FF;
  --purple-400: #A986FF;
  --purple-500: #8142FC;
  --purple-600: #8033F4;
  --purple-700: #7121E0;
  --purple-800: #5F1BBC;
  --purple-900: #4F189A;
  --purple-950: #300D68;

  /* GRAY COLORS */
  --gray-10: #F9F9F9;
  --gray-20: #E5E5E5;
  --gray-30: #B7B7B7;
  --gray-40: #848484;
  --gray-50: #52525B;
  --gray-60: #303035;

  /* ALERTS COLORS */
  --green-01: #D3EAC8;
  --green-02: #4C8435;
  --green-03: #345427;
  --red-01: #F8D6D3;
  --red-02: #CD4747;
  --red-03: #4E1B1D;
  --yellow-01: #F6EDCB;
  --yellow-02: #DEA73F;
  --yellow-03: #8D4D1B;
}

@font-face {
  font-family: 'Satoshi';
  src: url('./fonts/Satoshi-Regular.woff2') format('woff2'),
    url('./fonts/Satoshi-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Satoshi-Medium';
  src: url('./fonts/Satoshi-Medium.woff2') format('woff2'),
    url('./fonts/Satoshi-Medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Satoshi-Bold';
  src: url('./fonts/Satoshi-Bold.woff2') format('woff2'),
    url('./fonts/Satoshi-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Satoshi-Light';
  src: url('./fonts/Satoshi-Light.woff2') format('woff2'),
    url('./fonts/Satoshi-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Satoshi-Black';
  src: url('./fonts/Satoshi-Black.woff2') format('woff2'),
    url('./fonts/Satoshi-Black.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Adicione isso no seu index.css ou em um arquivo global de estilo */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
}



/* Altera a cor da borda de todos os TextFields quando com foco */

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: transparent !important;
}


.MuiTableRow-root td:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.MuiTableRow-root td:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}



.MuiTablePagination-actions button {
  color: #52525B;
}


button:active {
  transform: 'none' !important;

}

#chartjs-tooltip {
  opacity: 0 !important;
  background: #8142FC;
  color: #fff !important;
  border-radius: 6px !important;
  pointer-events: none !important;
  transition: opacity 1s ease !important;
  padding: 10px !important;
  min-width: 70px !important;
  margin-bottom: 100px !important;
  text-align: center !important;
  white-space: nowrap !important;
  transform: translateX(-50%) !important;
  margin-top: -40px !important;
  font-family: 'Satoshi-Light', sans-serif !important;
  letter-spacing: 1.5px !important;
}

#chartjs-tooltip.show {
  opacity: 1 !important;
}

#chartjs-tooltip::after {
  content: '' !important;
  position: absolute !important;
  bottom: -20px !important;
  left: 50% !important;
  margin-left: -12px !important;
  border-width: 20px 12px 0 12px !important;
  border-style: solid !important;
  border-color: var(--tooltip-bg, #8142FC) transparent transparent transparent;
}