/*
 
Desc: This is the CSS file for the PapperBlock component.
update: 12/08/2024

*/

.title {
    text-transform: uppercase;
    font-size: 24px;
    line-height: 1.6;
    font-weight: 100;  
    color: #01579B;
    margin-bottom: 0; /* Reduz o espaço entre title e desc */
    
}
  
.desc {
    line-height: 1.6;
    color: #3d3d3d;
    margin-top: 0; /* Reduz o espaço entre title e desc */
}
