#login-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-container {
  margin-top: 20px;  
}

.icon-card {
  white-space: nowrap;
}

.login-header {
  display: flex;
  justify-content: center; 
  align-items: center;  
  margin-bottom: 10px;
}

.login-header img {
  width: 150px;
  height: auto;
  filter: invert(100%);
}

.saiba-mais-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.saiba-mais-card h3,
.saiba-mais-card p {
  color: #20CD8D;
  font-size: 25px;
  margin-bottom: 12px;
  font-weight: bold;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  padding-left: 10px;
  text-transform: uppercase;
}

.platform-name {
  font-size: 24px;
  font-family: Arial, Helvetica, sans-serif;
  color: #2980b9;
  font-weight: bold;
  margin-top: 10px;
}

.card-wrapper {
  display: flex;
  height: 100%;  
}

.card-personalizado {
  width: 600px;  
  cursor: pointer;
  padding: 40px;
  margin: 10px;
  max-width: 300px;
  height: 100%;  
  display: flex;
  flex-direction: column;
  justify-content: space-between;  
  border-top-left-radius: 50px !important;
  border-bottom-right-radius: 50px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}