/*

Desc: This file contains the styles for the phone component.
updated: 12/08/2024

*/

.contain {
  position: relative;
}

.phonebody-external {
  margin: 0 auto;
  position: relative;
  background: rgb(44, 55, 61);
  width: 43vh;
  height: 85vh;
  border-radius: 6vh;
  padding: 1.5vh;
  border: 0.3vh solid rgb(44, 55, 61);
  zoom: 0.9;
  overflow: hidden;
}

.phonebody-external::before {
  content: '';
  position: absolute;
  top: -10px; 
  left: 50%;
  transform: translateX(-50%);
  width: 170px;
  height: 40px;
  background: rgb(44, 55, 61);
  border-radius: 0 0 50px 50px;
  z-index: 2;
}

.phonebody-external::after {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 154px; 
  height: 40px; 
  background: rgb(44, 55, 61);
  border-radius: 0 0 40px 40px; 
  z-index: 3;
}

.phonebody-internal {
  width: 100%;
  height: 100%;
  background: '#263238';
  background-size: 100% 100%;
}
