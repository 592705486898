/*

Desc: This file contains the styles for the Error page.
update 12/08/2024

*/

.ErroContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .ErroCircle {
    border: 2px solid #ffffff;
    border-radius: 50%;
    width: 500px;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px 5px rgba(92, 94, 94, 0.2); /* Adiciona uma sombra ao redor do círculo */
  }
  