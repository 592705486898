/* 

This file contains the styles for the Sidebar component.
Update: 12/08/2024

*/

.sidebar {
  width: 300px;
  background-color: #FFFFFF;
  min-height: 99vh;
  transition: width 0.3s ease;
  box-sizing: border-box;
  position: fixed;  
  top: 0; 
  left: 0;  
  bottom: 0;  
  overflow-y: auto; 
  box-shadow: 4px 0 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.sidebar.collapsed {
  width: 100px;
  padding-right: 0;
}

.sidebar.expanded {
  width: 220px;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar a:visited {
  color: inherit;
}


@media screen and ( max-width: 960px ) {
  .sidebar {
    width: 100px;
  }
}
