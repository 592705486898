.template_input[contentEditable="true"]:empty:before {
    content: attr(data-placeholder);
    color: rgb(132, 132, 132);
}

.template_input::-webkit-scrollbar {
    display: none;
}

.template_input {
    scrollbar-width: none;
}