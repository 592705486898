/* 

Desc: This is the CSS file for the MainContent component. 
Update: 12/08/2024

*/

.MainContent {
  overflow-y: auto;  
  scrollbar-width: thin;
  scrollbar-color: #ccc #F7F7FC;
  background-color: #F7F7FC;
  max-width: calc(100vw - 300px);

  margin-left: 300px;
  transition: margin-left 0.3s ease;

  &.collapsed {
    margin-left: 100px;
    max-width: calc(100vw - 100px)
  }

}

.full-width {
  max-width: 100vw;
  margin: 0;
}


@media screen and (max-width: 960px) {
  .MainContent {
    max-width: calc(100vw - 100px);
    margin-left: 100px;
  }
}

@media screen and (max-width: 600px) {
  .MainContent {
    margin-left: 0px;
    min-width: 100vw;
    padding-top: 85px;

    &.collapsed {
      margin-left: 0px;
      max-width: 100vw;
    }
  }
}